<template>
<!-- 核心人员 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/5.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb nuox-top">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[5].name") }}</span>
      </div>
      <div class="module-line"></div>
      <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[5].name") }}
              <div class="xian"></div>
            </div>
      </div>
    <div class="nuox-un-module">
         <div class="nuox-center">
            <div class="search-input person-input">
              <i class="el-icon-search"></i>
              <input type="text" class="" v-model="searchStr" placeholder="" @change="search"/>
            </div>
         </div>
         <div class="nuox-module">
           <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
            <ul class="menu-vertical-nav" v-if="$i18n.locale=='zh'">
              <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
                <div :class="'title '+[item.id==checkId?'open':'']" ><span v-html="item.name"></span><span :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              </li>
            </ul>
            <ul class="menu-vertical-nav" v-if="$i18n.locale=='en'">
              <li v-for="(item,index) in menusEn" :key="index" @click="checknav(item)">
                <div :class="'title '+[item.id==checkId?'open':'']" ><span v-html="item.name"></span><span :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
              </li>
            </ul>
          </div>
            <div class="person-list">
                <el-row>
                  <el-col :span="8" v-for="(item,index) in persons" :key="index">
                      <div class="person-card" @click="personItem(item)">
                        <el-image :src="item.imgSrc" class="personImg">
                          <div slot="placeholder" class="image-slot">
                            loading<span class="dot">...</span>
                          </div>
                        </el-image>
                        <div class="personContent">
                          <!-- 部门名称 -->
                          <p v-if="$i18n.locale=='en'" v-html="item.deptEn"></p>
                          <p v-if="$i18n.locale=='zh'" v-html="item.deptZh"></p>
                          <!-- 人员名 -->
                          <p v-if="$i18n.locale=='zh'" class="name" v-html="item.titleZh"></p>
                          <p v-if="$i18n.locale=='en'" class="name" v-html="item.titleEn"></p>
                          <!-- 职位名称 -->
                          <p v-if="$i18n.locale=='en'" v-html="item.peoplePositionEn"></p>
                          <p v-if="$i18n.locale=='zh'" v-html="item.peoplePositionZh"></p>
                          <!-- 人员介绍
                          <div class="personInfo" v-if="$i18n.locale=='zh'" v-html="item.commentZh"></div>
                          <div class="personInfo" v-if="$i18n.locale=='en'" v-html="item.commentEn"></div>
                          -->
                        </div>
                      </div>
                  </el-col>
                </el-row>
                <div class="nuox-page-center">
                <el-pagination
                      :small="true"
                      :background="true"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="pageNum"
                      :page-sizes="[9,18,36,72]"
                      :page-size="pageCount"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="totalCount">
                    </el-pagination>
                </div>
            </div>
         </div>
    </div>
     <div class="module-line"></div>
    </div>
     <el-dialog :visible.sync="dialogTableVisible" custom-class="self-dialog" width="800px">
        <div class="p-item-info">
          <el-row>
                <el-col :span="12" class="p-item-info-img">
                  <img :src="itemInfo.imgSrc" >
                </el-col>
                <el-col :span="12" class="p-item-info-cen">
                  <p v-if="$i18n.locale=='zh'" class="name" v-html="itemInfo.titleZh"></p>
                  <p v-if="$i18n.locale=='en'" class="name" v-html="itemInfo.titleEn"></p>
                  <div class="comment" v-if="$i18n.locale=='zh'" v-html="itemInfo.commentZh"></div>
                  <div class="comment" v-if="$i18n.locale=='en'" v-html="itemInfo.commentEn"></div>
                </el-col>
          </el-row>
        </div>
      </el-dialog>
      
  </div>
</template>
<script>
export default {
  data:function() {
    return {
        persons:[],
        menus:[
          {id:51,name:"高级管理团队",code:"level1"},
          {id:52,name:"技术运营团队",code:"level2"},
          {id:53,name:"事业发展团队",code:"level3"},
          {id:54,name:"综合运营团队",code:"level4"}
        ],
        menusEn:[
          {id:51,name:"SENIOR MANAGE TEAM",code:"level1"},
          {id:52,name:"TECHNOLOGY OPERATION TEAM",code:"level2"},
          {id:53,name:"CAREER DEVELOPMENT TEAM",code:"level3"},
          {id:54,name:"INTEGRATED OPERATION TEAM",code:"level4"}
      ],
        checkId:51,
        checkChildrenId:0,
        searchStr:'',
        pageNum:1,
        pageCount:9,
        totalCount:0,
        dialogTableVisible:false,
        itemInfo:{},
        code:"level1"
    };
  },
  mounted(){
    if(this.$route.query.id){
      this.checkId=this.$route.query.id;
      var menus = this.menus;
      for(var key in menus){
        if(menus[key].id==this.checkId){
          this.code=menus[key].code;
        }
      }
    }
    this.init();
  },
  methods:{
    async init(){
      var that = this;
      var baseUrl = this.$baseURL;
      var search = this.searchStr;
      var code = this.code;
      const { data: res } = await this.$http.get(
        "webSite/core_prople?pageNum="+that.pageNum+"&pageCount="+that.pageCount+"&search="+search+"&code="+code
      );
      if(res.code==0){
        var data = res.data.data;
        that.totalCount = res.data.count;
          var persons = [];
          for(var key in data){
              var imgSrc = '';
              if(data[key].attList&&data[key].attList.length>0){
                imgSrc = baseUrl+data[key].attList[0].attPath+data[key].attList[0].attRealName
              }
              var new1 = {};
              new1.commentZh = data[key].propleDesZh;
              new1.commentEn =  data[key].propleDesEn;
              new1.titleZh =  data[key].peopleNameZh;
              new1.titleEn =  data[key].peopleNameEn;
              new1.deptZh =  data[key].deptNameZh;
              new1.deptEn =  data[key].deptNameEn;
              new1.peoplePositionZh =  data[key].peoplePositionZh;
              new1.peoplePositionEn =  data[key].peoplePositionEn;
              new1.jobNumber =  data[key].jobNumber;
              new1.peopleId =  data[key].peopleId;
              new1.imgSrc = imgSrc;
              persons.push(new1);
          }
          that.persons= persons;
      }
    },
    search(){
        this.pageNum=1;
        this.pageCount=9;
        this.init();
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.pageNum=1;
      this.pageCount=9;
      if(e.id==this.checkId){
        this.code="";
        this.checkId=50;
      }else{
        this.code=e.code;
        this.checkId = e.id;
      }
      
      this.init();
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    },
    handleSizeChange(val) {
        this.pageCount = val;
        this.init();
      },
      handleCurrentChange(val) {
        this.pageNum = val;
        this.init();
      },personItem(o){
        this.dialogTableVisible=true;
        this.itemInfo = o;
      }
  }
}
</script>
<style>
@import '../assets/css/about.css';
.nuox-top{
    margin-top: 50px;
}
.nuox-center{
  text-align: center;
}
.nuox-center .search-input{
  margin:0 auto;
  width: 270px;
  border: 2px solid #00448F;
}
.nuox-center .search-input .el-icon-search{
  color: #00448F;
  font-weight: bold;
}
.search-input.person-input input{
  width: 220px;
}
.person-list{
  margin-top: 20px;
}
.person-card{
  position: relative;
  height: 350px;
  width: 263px;
  margin: auto;
  margin-top: 40px;
}
.personImg{
  width: 100%;
  height: 100%;
}
.personContent{
  position:absolute;
  background-color:rgba(237,241,244,0.99);
  bottom: 0px;
  width: 100%;
  height: 110px;
  padding: 20px 0;
}
.personContent p{
  margin: 0;
  padding: 5px 15px;
}
.personContent p.name{
  font-size: 17px;
  font-weight: bold;
}
/*
.personContent .personTilte{
  margin-bottom: 10px;
}

.personContent .personTilte .p-name{
  color: #00448F;
  font-weight: bold;
  font-family: "Microsoft YaHei";
  font-size: 16px;
}
.personContent .personTilte .p-no{
  color: #00448F;
  font-weight: bold;
  font-family: "Microsoft YaHei";
  font-size: 14px;
  margin-left: 10px;
}
.personContent .personInfo{
  line-height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}*/

.nuox-page-center{
 margin: 30px 0;
 text-align: center;
}
.p-item-info-img{
  background-color: #999DA4;
}
.p-item-info-img img{
  width: 100%;
  height: auto;
  display: block;
  padding: 0;
  margin: 0;
}
.self-dialog .el-dialog__header{
  display: none;
}
.self-dialog .el-dialog__body{
  margin: 0;
  padding: 0;
}
.p-item-info-cen{
  padding: 15px;
}
.p-item-info-cen .name{
  font-size: 25px;
  color: #333;
  font-weight: bold;
  margin: 5px 0;
}
.p-item-info-cen .comment{
  font-size: 16px;
  color: #666;
  font-weight: bold;
  margin: 10px 0;
}
</style>